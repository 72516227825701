import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link as RebassLink } from "rebass/styled-components";
import { Link as GatsbyLink } from "gatsby";
import { AnchorLink as GatstbyAnchorLink } from "gatsby-plugin-anchor-links";
import { Link as AnchorLinkScroll } from "react-scroll";

const Link = ({ href, external, children, activeClassName, ...props }) => {
  href = href || "";
  activeClassName = activeClassName || "active";
  const [address, setAddress] = useState(href);
  useEffect(() => {
    if (typeof window !== "undefined" && address.includes("#")) {
      setAddress(address.replace(window.location.pathname, ""));
    }
  }, []);

  const isAnchor = address.includes("#");
  const isExternal = address.startsWith("http") || external;
  if (isAnchor) {
    if (address.startsWith("#")) {
      return (
        <AnchorLinkScroll
          activeClass={activeClassName}
          to={address.replace("#", "")}
          spy={true}
          smooth={true}
          offset={-82}
          duration={500}
        >
          {children}
        </AnchorLinkScroll>
      );
    }
    return (
      <GatstbyAnchorLink to={address} {...props} activeClassName={activeClassName}>
        {children}
      </GatstbyAnchorLink>
    );
  } else if (!isExternal) {
    if (address === "/") {
      return (
        <GatsbyLink to={address} {...props} activeClassName={activeClassName}>
          {children}
        </GatsbyLink>
      );
    } else {
      return (
        <GatsbyLink
          to={address}
          {...props}
          activeClassName={activeClassName}
          getProps={({ isPartiallyCurrent }) =>
            isPartiallyCurrent ? { className: "active" } : null
          }
        >
          {children}
        </GatsbyLink>
      );
    }
  }
  return (
    <RebassLink href={address} {...props} target="_blank" rel="noopener">
      {children}
    </RebassLink>
  );
};

Link.propTypes = {
  activeClassName: PropTypes.any,
  children: PropTypes.any,
  external: PropTypes.any,
  href: PropTypes.any
};
export default Link;
