const variants = {
  avatar: {
    width: "avatar",
    height: "avatar",
    borderRadius: "circle"
  },
  badges: {
    display: "inline-block",
    px: 2,
    py: 1,
    borderRadius: 9999,
    fontSize: 0
  },
  badge: {
    primary: {
      variant: "variants.badges",
      color: "white",
      bg: "primary"
    }
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    fontWeight: "inherit"
  },
  linktext: {
    color: "inherit",
    textDecoration: "inherit"
  },
  darkBg: {
    backgroundColor: "primary",
    color: "background"
  },
  bgImg: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  squareBox: {
    position: "relative",
    "&:after": {
      content: "''",
      display: "block",
      paddingBottom: "100%"
    }
  },
  nav: {
    fontSize: 1,
    fontWeight: "bold",
    display: "inline-block",
    p: 2,
    color: "inherit",
    textDecoration: "none",
    ":hover,:focus,.active": {
      color: "primary"
    }
  },
  cookiesBanner: {
    position: "fixed",
    bottom: "0",
    left: 0,
    right: 0,
    zIndex: 99,
    transition: "all 300ms ease",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)"
  },
  formItemError: {
    "input,textarea,select": {
      borderColor: "danger"
    },
    ".checkit": {
      color: "danger"
    }
  },
  navbar: {
    width: ["100%"],
    transition: "all 300ms ease",
    minHeight: ["70px"],
    backgroundColor: "transparent",
    color: "#FFF",
    a: {
      color: "#FFF"
    },
    ".logo": {
      display: "block",
      lineHeight: 0
    },
    position: "absolute",
    zIndex: 10,
    "&.sticky": {
      backgroundColor: "background",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)",
      color: "text",
      a: {
        color: "text"
      }
    }
  },
  container: {
    width: "100%",
    maxWidth: ["100%", "100%", "960px", "1200px"],
    m: "0 auto",
    flexbox: true,
    px: [4, 4, 6]
  },
  containerFluid: {
    width: "100%",
    m: "0 auto",
    flexbox: true,
    px: [4, 4, 6]
  },
  grid: {
    flexWrap: "wrap",
    mx: [-2, -2, -3]
  },
  gridItem: {
    px: [2, 2, 3]
  },
  section: {
    width: "100%",
    pt: [10, 10, 11],
    pb: [10, 10, 11]
  },
  card: {
    boxShadow: "card",
    borderRadius: "default",
    overflow: "hidden",
    position: "relative",
    bg: "white",
    transition: "all 300ms ease",
    button: {
      position: "relative",
      zIndex: "2"
    },
    "&:hover": {
      boxShadow: "cardHover",
      transform: "translateY(-2px)"
    }
  },
  overlink: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    textIndent: "-9999px",
    overflow: "hidden",
    zIndex: "1"
  },
  horizontalCard: { variant: "variants.card" },
  verticalCard: { variant: "variants.card" }
};

export default variants;
