import styled, { createGlobalStyle } from "styled-components";
import { width, height, color, space, boxShadow, borderRadius } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
    &:focus {
      outline: none;
      outline-color: transparent;
      outline-style: auto;
      outline-width: 0px;
    }
  }
  pre {
    overflow-x: scroll;
  }
  body{
    margin: 0;
    padding: 0;
	  overflow-x: hidden;
    font-size: ${themeGet("fontSizes.2")}px;
    font-family: ${themeGet("fonts.body")};
    line-height: ${themeGet("lineHeights.body")};
    color: ${themeGet("colors.text")};
    img {
      max-width:100%;
    }
    a{
      color:inherit;
      text-decoration:inherit;
    }
    div {
      & > p {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: ${themeGet("fonts.heading")};
    font-weight: ${themeGet("fontWeights.heading")};
  }
  h1 {
    font-size: ${themeGet("fontSizes.8")}px;
    color: black;
  }
  h2 {
    font-size: ${themeGet("fontSizes.6")}px;
    line-height: ${themeGet("fontSizes.6")}px;
    color: black;
    margin-bottom: ${themeGet("space.4")}px;
    text-transform: uppercase;
    &:after {
      content: "";
      display: block;
      width: 50px;
      height: 4px;
      background: ${themeGet("colors.primary")};
      margin-top: ${themeGet("space.3")}px;
    }
  }
  h3 {
    font-size: ${themeGet("fontSizes.6")}px;
    line-height: ${themeGet("fontSizes.6")}px;
    color: black;
    margin-bottom: ${themeGet("space.4")}px;
    &:after {
      content: "";
      display: block;
      width: 50px;
      height: 4px;
      background: ${themeGet("colors.primary")};
      margin-top: ${themeGet("space.3")}px;
    }
  }
  h4 {
    font-size: ${themeGet("fontSizes.4")}px;
    line-height: ${themeGet("fontSizes.4")}px;
    color: black;
    margin-bottom: ${themeGet("space.3")}px;
  }
  .react-parallax-bgimage{
    max-width:unset;
  }
  .mapboxgl-ctrl-attrib{
    display:none !important;
  }
  li::marker {
    color: ${themeGet("colors.primary")};
  }
`;

const SiteWrapper = styled.div`
  /* Style system supported prop */
  ${width}
  ${height}
  ${color}
  ${space}
  ${boxShadow}
  ${borderRadius}
`;

GlobalStyle.displayName = "GlobalStyle";
SiteWrapper.displayName = "SiteWrapper";

export { GlobalStyle, SiteWrapper };
