import PropTypes from "prop-types";
import React from "react";
import { Box } from "rebass/styled-components";

const Container = ({ fluid, children, maxContainer, ...props }) => {
  fluid = fluid || false;
  maxContainer = maxContainer || false;
  return (
    <Box variant={fluid ? "containerFluid" : "container"} {...props}>
      <Box maxWidth={maxContainer ? "1000px" : "auto"} mx={maxContainer ? "auto" : "0"}>
        {children}
      </Box>
    </Box>
  );
};

Container.propTypes = {
  children: PropTypes.any,
  fluid: PropTypes.bool,
  maxContainer: PropTypes.bool
};

export default Container;
