import PropTypes from "prop-types";
import React from "react";
import { Flex, Box, Heading } from "rebass/styled-components";
import Container from "./Container";
import { withTheme } from "styled-components";

const PageTitle = ({ title, headerImage, theme, ...props }) => {
  return (
    <Flex
      sx={{
        position: "relative",
        minHeight: ["250px", "250px", "300px"],
        "&:before": {
          content: "''",
          display: "block",
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: "102vw",
          height: "100%",
          background: headerImage
            ? `url(${headerImage.childImageSharp.fluid.src}) no-repeat center`
            : theme.colors.primary,
          backgroundSize: "cover",
          zIndex: 0
        },
        "&:after": {
          content: '""',
          position: "absolute",
          zIndex: 1,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          height: "100%",
          backgroundImage:
            "radial-gradient(circle, " +
            theme.colors.primary +
            "99 0%, " +
            theme.colors.primary +
            "99 100%)"
        }
      }}
    >
      <Container
        sx={{
          position: "relative",
          zIndex: 2,
          mt: "auto"
        }}
      >
        <Box py={[6, 6, 8]}>
          <Heading as="h1" variant="h1">
            {title}
          </Heading>
        </Box>
      </Container>
    </Flex>
  );
};

PageTitle.propTypes = {
  title: PropTypes.any,
  headerImage: PropTypes.any,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary: PropTypes.any
    })
  })
};

export default withTheme(PageTitle);
