import PropTypes from "prop-types";
import React, { useState } from "react";
import { withTheme } from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Section from "../UI/Section";
import Container from "../UI/Container";
import CustomImage from "../UI/CustomImage";
import Link from "../UI/Link";
import Icon from "../UI/Icon";
import Markdown from "../UI/Markdown";
import { Box, Flex, Text, Button } from "rebass/styled-components";

const FooterRaw = ({ theme, menusSettings, footerSettings, logoAlt }) => {
  logoAlt = logoAlt || "Logo";
  const [stiked, setStiked] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
  };
  var telLink = "tel:+687";
  var mailTo = "mailto:";

  if (!menusSettings.activeFooter) {
    return <></>;
  }
  return (
    <Section bg="footer" color="#fff" py={[0, 0, 0, 0]}>
      <Container py={6}>
        <Flex
          justifyContent={["center", "space-between"]}
          flexDirection={["column", "row"]}
          flexWrap="wrap"
        >
          <Box mr={[0, 0, 13]} py={6} width={["100%", "auto"]}>
            <CustomImage
              img={footerSettings.logoFooter}
              height="auto"
              width="170px"
              mx={["auto", "auto", 0]}
            />
          </Box>
          {footerSettings.socials && (
            <Box py={6} ml={[0, 0, 0, "auto"]} mb={8} width={["100%", "auto"]}>
              <Flex justifyContent="center">
                <Box>
                  <a href={footerSettings.socials.facebook} target="_blank">
                    <Box color="primary" sx={{ "&:hover": { color: "white" } }}>
                      <Icon icon="facebook" size={45} />
                    </Box>
                  </a>
                </Box>
                {footerSettings.socials.linkedin && (
                  <Box ml={6}>
                    <a href={footerSettings.socials.linkedin} target="_blank">
                      <Box color="primary" sx={{ "&:hover": { color: "white" } }}>
                        <Icon icon="linkedin" size={45} />
                      </Box>
                    </a>
                  </Box>
                )}
              </Flex>
            </Box>
          )}
        </Flex>
      </Container>

      <Box>
        <Container>
          <Flex
            justifyContent={["center", "center", "space-between"]}
            flexDirection={["column", "column", "row"]}
            alignItems="center"
            py={3}
            sx={{
              borderStyle: "solid",
              borderWidth: "1px 0 0 0",
              borderColor: "grayLight",
              a: {
                "&:hover": {
                  color: "primary"
                }
              }
            }}
          >
            <Flex
              justifyContent={["center", "center", "flex-start"]}
              flexDirection={["column", "row"]}
              alignItems="center"
            >
              {menusSettings.footermenu.map((item, i) => (
                <Box ml={[0, 3, 0]} mr={[0, 3, 6]} mb={[3, 0, 0]} key={"menu-" + i}>
                  <Link activeClassName="active" href={item.path} external={item.external}>
                    <Button variant="ninja">
                      <Text fontSize={1}>{item.title}</Text>
                    </Button>
                  </Link>
                </Box>
              ))}
            </Flex>
            <Box fontSize={1} mt={[6, 6, 0]}>
              <>
                Réalisation :{" "}
                <a href="https://la-fabrik.nc">
                  <strong>La Fabrik</strong>
                </a>
              </>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Section>
  );
};

FooterRaw.propTypes = {
  logoAlt: PropTypes.string,
  footerSettings: PropTypes.shape({
    addressFooter: PropTypes.any,
    logoFooter: PropTypes.any,
    phoneFooter: PropTypes.any,
    mailFooter: PropTypes.any,
    opentimeFooter: PropTypes.any,
    socials: PropTypes.any
  }),
  menusSettings: PropTypes.shape({
    mainmenu: PropTypes.array,
    footermenu: PropTypes.array,
    footermenuen: PropTypes.array,
    activeFooter: PropTypes.bool
  }),
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};

const Footer = ({ theme }) => {
  const { allMarkdownRemark, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(filter: { frontmatter: { title: { in: ["menus", "footer"] } } }) {
          nodes {
            frontmatter {
              title
              activeFooter
              mainmenu {
                offset
                path
                title
                external
              }
              footermenu {
                offset
                path
                title
                external
              }
              logoFooter {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 300, quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              addressFooter
              phoneFooter
              mailFooter
              opentimeFooter
              socials {
                linkedin
                facebook
              }
            }
          }
        }
      }
    `
  );

  const menusSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "menus") || {};
  const menusSettings = menusSettingsFrontmatter.frontmatter
    ? menusSettingsFrontmatter.frontmatter
    : {};
  const footerSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "footer") || {};
  const footerSettings = footerSettingsFrontmatter.frontmatter
    ? footerSettingsFrontmatter.frontmatter
    : {};
  return (
    <FooterRaw
      theme={theme}
      menusSettings={menusSettings}
      footerSettings={footerSettings}
      logoAlt={site.siteMetadata.title}
    />
  );
};

Footer.propTypes = {
  theme: PropTypes.any
};

export { FooterRaw };
export default withTheme(Footer);
