import colors from "./colors";
import { buildButtons } from "./buttons";
import variants from "./variants";
import forms from "./forms";

const theme = {
  colors: colors,
  forms: forms,
  fonts: {
    monospace: "Menlo, monospace"
  },
  fontSizes: [12, 14, 16, 20, 22, 24, 32, 40, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  space: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 80, 100, 128, 256, 512],
  sizes: {
    avatar: 48
  },
  radii: {
    square: 0,
    default: 2,
    big: 4,
    circle: 99999
  },
  shadows: {
    card: "0 0 5px rgba(82, 82, 82, .25)",
    cardHover: "0 2px 10px rgba(82, 82, 82, .25)"
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading"
    },
    h1: {
      fontSize: 8,
      color: "black"
    },
    h2: {
      fontSize: 6,
      color: "black",
      mb: 6,
      textTransform: "uppercase"
    },
    h3: {
      fontSize: 6,
      color: "black",
      mb: 4
    },
    h4: {
      fontSize: 4,
      color: "black",
      mb: 3
    },
    blockTitle: {
      color: "black",
      fontSize: 6,
      textTransform: "uppercase",
      display: "table",
      mx: "auto",
      mb: 4,
      "&:after": {
        width: "100%",
        mt: 1
      }
    },
    display: {
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7]
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
      fontSize: "inherit"
    }
  },
  variants: variants,
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body"
    }
  },
  gutter: 30
};
export default theme;

const buildTheme = settings => {
  theme.fonts = {
    body: settings.font.fontname + ", sans-serif",
    heading: settings.fontheads.fontname + ", sans-serif",
    monospace: "Menlo, monospace"
  };

  theme.colors = {
    ...theme.colors,
    ...settings.colors
  };
  theme.buttons = buildButtons(theme.colors);
  theme.breakpoints = [
    settings.breakpoints.small,
    settings.breakpoints.medium,
    settings.breakpoints.large,
    settings.breakpoints.huge
  ];
  return theme;
};

export { buildTheme };
