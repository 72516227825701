import PropTypes from "prop-types";
import React from "react";
import { Box } from "rebass/styled-components";

const Section = props => {
  return (
    <Box width="100%" pt={[10, 11, 12]} pb={[10, 11, 12]} {...props}>
      {props.children}
    </Box>
  );
};

Section.propTypes = {
  children: PropTypes.any
};

export default Section;
