import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import Container from "../UI/Container";
import Link from "../UI/Link";
import { useStaticQuery, graphql } from "gatsby";
import CustomImage from "../UI/CustomImage";
import LangSwitcher from "../UI/LangSwitcher";
import Drawer from "./Drawer";

const NavbarRaw = ({ menusSettings, logoAlt }) => {
  logoAlt = logoAlt || "Logo";
  const [stiked, setStiked] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setStiked(position > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  if (!menusSettings.activeTopMenu) {
    return <></>;
  }
  return (
    <Box
      top={0}
      width={1}
      sx={{
        top: 0,
        zIndex: 5,
        position: "fixed",
        ".active button": {
          fontWeight: "bold !important",
          "&:after": {
            content: "''",
            display: "block",
            height: "4px",
            width: "100%",
            bg: "primary",
            position: "absolute",
            left: "0",
            top: "100%",
            mt: "2px"
          }
        }
      }}
    >
      <Box
        variant="navbar"
        className="sticky"
        sx={{
          borderWidth: "0 0 2px 0",
          borderStyle: "solid",
          borderColor: "primary",
          minHeight: "auto"
        }}
      >
        <Container>
          <Flex alignItems="center">
            <Box px={[3, 4]} pt={[2, 3]} pb={[1, 2]} bg="primary" flex="0 0 auto">
              <Link href="/">
                <CustomImage
                  height={["30px", "40px", "50px"]}
                  width="auto"
                  img={menusSettings.logo}
                  alt={logoAlt}
                />
              </Link>
            </Box>
            <Box mx="auto" />
            <Box
              alignItems="center"
              display={["none", "none", "flex"]}
              width="100%"
              justifyContent="space-between"
              paddingLeft={["10%", "10%", "10%", "20%"]}
            >
              {menusSettings.mainmenu
                .filter(item => item.inNavBar)
                .map((item, i) => (
                  <Link
                    activeClassName="active"
                    href={item.path}
                    external={item.external}
                    key={"menu-" + i}
                  >
                    <Button variant="mainMenuItem">
                      <Text>{item.title}</Text>
                    </Button>
                  </Link>
                ))}
              <LangSwitcher />
            </Box>
            <Drawer
              placement="right"
              width={["100%", "400px"]}
              closeButton={
                <Box textAlign="right">
                  <Button
                    label="close-menu"
                    aria-label="close-menu"
                    variant="mainMenuItem"
                    p={3}
                    color="text"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="butt"
                      strokeLinejoin="arcs"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </Button>
                </Box>
              }
              drawerHandler={
                <Button
                  label="open-menu"
                  aria-label="open-menu"
                  variant="ninja"
                  pl={2}
                  display={["flex", "flex", "none"]}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="butt"
                    strokeLinejoin="arcs"
                  >
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                  </svg>
                </Button>
              }
              open={open}
              toggleHandler={toggleHandler}
            >
              <Box
                bg="background"
                sx={{
                  ".active button:after": {
                    height: "100%",
                    width: "6px",
                    top: 0
                  }
                }}
              >
                {menusSettings.mainmenu
                  .filter(item => item.inSideBar)
                  .map((item, i) => (
                    <Link href={item.path} external={item.external} key={i}>
                      <Button
                        variant="mainMenuItem"
                        pl={5}
                        pr={3}
                        py={3}
                        width="100%"
                        textAlign="left"
                        onClick={toggleHandler}
                      >
                        <Text color="text">{item.title}</Text>
                      </Button>
                    </Link>
                  ))}
              </Box>
            </Drawer>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

NavbarRaw.propTypes = {
  logoAlt: PropTypes.string,
  menusSettings: PropTypes.shape({
    logo: PropTypes.any,
    logo2: PropTypes.any,
    mainmenu: PropTypes.any,
    activeTopMenu: PropTypes.bool
  }),
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};

const Navbar = ({ theme }) => {
  const { markdownRemark, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        markdownRemark(frontmatter: { settingName: { eq: "menus" } }) {
          frontmatter {
            activeTopMenu
            mainmenu {
              external
              inNavBar
              inSideBar
              offset
              path
              title
            }
            menutopbartype
            logo2 {
              publicURL
              childImageSharp {
                fluid(maxHeight: 75, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            logo {
              publicURL
              childImageSharp {
                fluid(maxHeight: 75, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <NavbarRaw
      theme={theme}
      menusSettings={markdownRemark.frontmatter}
      logoAlt={site.siteMetadata.title}
    />
  );
};

Navbar.propTypes = {
  theme: PropTypes.any
};

export { NavbarRaw };
export default Navbar;
